.card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 450px;
}
/* 
.frame {
  border-radius: 4px;
  font-size: 2em;
  color: #fff;
  background-color: cadetblue;
  padding: 0.5em;
  display: flex;
  align-items: center;
  box-shadow: 11px 7px 10px rgba(0, 0, 0, 0.09);
} */

.group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
}

.dataGroup {
  display: flex;
  align-items: center;
}

.dataGroup > svg {
  font-size: 2.3em;
}

.time,
.date {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
  font-size: 1.2em;
}
