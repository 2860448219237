@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --dark-gray: #32312d;
  --light-gray: rgba(70, 71, 71, 0.774);
}

.App {
  display: flex;
  font-family: 'Poppins', serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;