.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4.5px 10px rgba(0, 0, 0, 0.09),
    0px 36px 80px rgba(0, 0, 0, 0.18);
  padding: 1em;
}

.title {
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 700;
  padding-left: 1em;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.25rem;

  width: 100%;
  height: 150px;
  overflow-y: scroll;
  padding: 1em;
}
