.container {
  background: linear-gradient(to top right, rgb(113, 2, 2) 0%, #870546, black 100%);
  height: 100vh;
  text-align: center;
  padding: 1em;
}

.card {
  /* background-color: #794c4c; */
  background: linear-gradient(to top right, red 0%, black 100%);
  border-radius: 10px;
  padding: 1em;
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px rgb(32, 24, 24), 0 0 1em blue, 0 0 0.2em blue;

  box-shadow: 0px 5px 5px 2px white;
  transition: all 0.3s;
}

.cards {
  background: linear-gradient(to top right, rgb(144, 25, 177) 0%, #870546, #1c0118, green);

  border-radius: 10px;
  /* padding: 1em; */
  box-shadow: 0px 5px 5px 2px white;
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  box-shadow: 0px 10px 0px rgba(18, 1, 7, 0.494);
  transition: all 0.3s;
}

.cardsk {
  background: linear-gradient(to top right, rgb(144, 25, 177) 0%, #870546, #1c0118, green);

  border-radius: 10px;
  /* padding: 1em; */
  box-shadow: 0px 5px 5px 2px white;
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  /* box-shadow: 0px 10px 0px rgba(18, 1, 7, 0.494); */
  transition: all 0.3s;
}

.cards1 {
  background: linear-gradient(to top right, rgb(49, 50, 0) 0%, #1c0118, #023636 100%);
  border-radius: 10px;
  /* padding: 1em; */
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  box-shadow: 0px 5px 5px 2px white;
  transition: all 0.3s;
}

.cards2 {
  background: linear-gradient(to top right, rgb(25, 177, 101) 0%, #870546 100%);
  border-radius: 10px;
  /* padding: 1em; */
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 5px 2px white;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  box-shadow: 0px 10px 0px rgba(255, 255, 255, 0.494);
  transition: all 0.3s;
}

.cards3 {
  background: linear-gradient(to top right, rgb(100, 2, 122) 0%, #642e25 100%);
  border-radius: 10px;
  /* padding: 1em; */
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  box-shadow: 0px 5px 5px 2px white;
  transition: all 0.3s;
}

.cards4 {
  background: linear-gradient(to top right, red 0%, #d90870, #1c0118, green 100%);
  border-radius: 10px;
  /* padding: 1em; */
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  box-shadow: 0px 5px 5px 2px white;
  transition: all 0.3s;
}

.cards5 {
  background: linear-gradient(to top right, red 0%, black 100%);
  border-radius: 10px;
  /* padding: 1em; */
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  box-shadow: 0px 5px 5px 2px white;
  transition: all 0.3s;
}

.cards6 {
  background: linear-gradient(to top right, #8447c1, 0%, #b90686, yellow, #1c0118 100%);
  border-radius: 10px;
  /* padding: 1em; */
  text-decoration: none;
  flex: 1;
  min-width: 150px;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  box-shadow: 0px 5px 5px 2px white;
  transition: all 0.3s;
}

.icon {
  font-size: 5em;
  color: cornflowerblue;
  transition: all 0.3s;
}

.card:hover {
  transform: translateY(-3px);
  color: #000;
}

.card:hover .icon {
  transform: scale(1.1);
}

h3,
p {
  color: #ffffff;
  text-decoration: none;
}