.iconFrame {
  background-color: cornflowerblue;
  padding: 0.5em;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 8px;
}

.profileIcon {
  color: #fff;
  font-size: 1.3em;
}

.username {
  font-size: 1.2em;
  color: #fff;
  margin: 0;
}

.btn {
  background: none;
  border: none;
  transition: all 0.2s;
}

.icon {
  font-size: 1.4em;
  cursor: pointer;
  color: chocolate;
}

.btn:hover {
  color: coral;
  transform: scale(1.3);
}
