.container {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0.25em;
  box-shadow: 0px 4px 0px rgba(227, 225, 225, 0.8);
}

.icon {
  background-color: cornflowerblue;
  padding: 0.25em;
  border-radius: 8px;
  border: solid 2px #fff;
}

.icon>svg {
  font-size: 3em;
  color: #fff;
}

.name {
  font-size: 1.2em;
  font-weight: 600;
}

.rating {
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  color: green;
}

.clock {
  font-size: 1.5em;
  font-weight: bold;
  padding: 0.5rem;
}