body {
    font-family: Arial, Helvetica, sans-serif;
    /* background-image: url('gpm.jpg'); */
    background-size: cover;
    background-attachment: fixed;

}

/* align items center vertically and horizontally  */
.container {
    display: flex;
    justify-content: center;
    align-items: center !important;
    height: 100vh;
}

.form {
    width: 500px;
    height: 650px;
    background-color: rgb(0, 0, 0);
    box-shadow: 0 2px 6px white;
    border-radius: 10px;
}

.btn button {
    padding: 3px;
    margin: 30px 0px 40px 30px;
    border-style: none;
    background-color: transparent;
    color: beige;
    font-size: 18px;
    font-weight: 550;
}

.formGroup {
    display: flex;
    justify-content: center;
}

.formGroup input {
    border: none;
    width: 80%;
    border-bottom: 2px solid white;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    color: white;
}

input:focus {
    outline: none !important;
    border-bottom: 2px solid rgb(91, 243, 131);
    font-size: 17px;
    font-weight: bold;
    color: white;
}

::placeholder {
    color: white;
}

.checkBox {
    display: flex;
    justify-content: center;
    margin: 16px !important;
}

#checkbox {
    margin-right: 10px;
    height: 15px;
    width: 15px;
}

.text {
    color: rgb(199, 197, 197);
    font-size: 13px;
}

.btn2 {
    padding: 10px;
    width: 150px;
    border-radius: 20px;
    background-color: rgb(10, 136, 43);
    border-style: none;
    color: white;
    font-weight: 600;
}

.btn2:hover {
    background-color: rgba(10, 136, 43, 0.5);
}

.btn button:hover {
    border-bottom: 2px solid rgb(91, 243, 131);
}

/* hide signup form */
.login {
    display: none;
}

/* Login form code */
.login {
    margin-top: 40px;
    text-align: center;
}

.login .checkBox {
    margin-top: 30px !important;
}