.card {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  background-color: #fff;
  border-radius: 8px;
  max-width: 450px;
}

.icon {
  font-size: 4rem;
  color: var(--bs-green);
}

.title {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  border: solid 1px green;
}

.btnGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  flex-wrap: wrap;
}

.btn {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    var(--bs-purple) 0,
    var(--bs-indigo) 100%
  );
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  color: #fff;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 1.1em;
}

.btn:focus {
  box-shadow: var(--bs-indigo) 0 0 0 1.5px inset,
    rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    var(--bs-indigo) 0 -3px 0 inset;
}

.btn:hover:not(:disabled) {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, var(--bs-indigo) 0 -3px 0 inset;
  transform: translateY(-2px);
}

.btn:disabled {
  cursor: auto;
}

.btn:active:not(:disabled) {
  box-shadow: var(--bs-indigo) 0 3px 7px inset;
  transform: translateY(2px);
}
