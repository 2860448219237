.container {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.bar {
  width: 25px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.progress {
  width: 100%;
  background-color: #272727;
}

.badge {
  background-color: darkgreen;
  color: #fff;
  font-size: 0.9em;
  padding: 0.25em 0.5em;
  border-radius: 4px;
}
