.container {
  min-height: 100vh;
  background-color: #272727;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 1.5em;
}

.brand {
  color: darkgreen;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 2.3em;
  margin: 0;
}

.closeBtn {
  background: none;
  border: none;
  font-size: 3em;
  color: #fff;
  display: none;
}

.menuBtn {
  background: none;
  border: none;
}

.bars {
  color: #fff;
  font-size: 2.5em;
}

.menuList {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.navLink {
  /* padding: 1rem 2rem; */
  display: flex;
  gap: 0.5em;
  align-items: center;
  text-decoration: none;
  font-size: 1.3em;
  transition: all 0.2s;
  color: #fff;
  font-weight: 500;
}

.navLink>svg {
  color: #fff;
  font-size: 1.5em;
}

/* .navLink:hover {
  background-color: lightblue;
  color: #272727;
} */

/* .navLink:hover>svg {
  color: darkgreen;
} */

.hide {
  display: none;
}

/* 
.logInBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  width: 80%;
  align-self: center;
  text-decoration: none;
  border: none;
  background: cornflowerblue;
  border-radius: 8px;
  padding: 0.25em 0.5em;
  color: #fff;
  text-align: center;
  margin-top: 1em;
  box-shadow: 0px 1.8px 0px rgba(0, 0, 0, 0.12),
    0px 14px 0px rgba(0, 0, 0, 0.24);
  transition: all 0.3s;
}
*/
.logBtn {
  background-color: var(--bs-indigo);
  background-image: radial-gradient(100% 100% at 100% 0,
      var(--bs-purple) 0,
      var(--bs-indigo) 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  font-size: 18px;
  padding: 0.100em 0.5em;
  color: #fff;
  text-align: center;
  /* margin-top: 1em; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  width: 15%;
  height: 45px;
  align-self: center;
}

.logBtn:focus {
  box-shadow: #550e9c 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #551398 0 -3px 0 inset;

}

/* .logBtn:hover {
  color: #fff;
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #551398 0 -3px 0 inset;
  transform: translateY(-2px);
} */

.logBtn:active {
  box-shadow: #551398 0 3px 7px inset;
  transform: translateY(2px);
}

/* @media (max-width: 768px) {
  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
  }

  .hide {
    display: block;
  }

  .bars,
  .brand {
    display: none;
  }

  .closeBtn {
    display: block;
    flex: 1;
    text-align: left;
  }
} */
/* Sidebar.module.css */

.bottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #0f044b;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
  padding: 9px;
}

.navLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 14px;
}

.navLink svg {
  font-size: 24px;
}

.navLink.active {
  color: green;
  /* Example of active link color */
}

/* .logBtn {
  display: flex;
  flex-direction: column; 
  align-items: center;
  text-decoration: none;
  color: #444444;
  font-size: 14px;
} */

/* .logBtn svg {
  font-size: 240px;
} */

.text {
  margin-top: 1px;
}